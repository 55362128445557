export const esUS = {
  routes: {
    contribution: {
      declineLabel: 'Me niego a firmar este Documento',
    },
    ehr: {
      components: {
        introPage: {
          secureDataMessage:
            'Sus datos médicos electrónicos están seguros. Para conocer más, haga clic ',
          introTipTitle: 'Usted necesitará: ',
        },
        findPage: {
          connectButtonText: 'Conectarse',
        },
      },
    },
    followUp: {
      components: {
        complete: {
          title: '¡Gracias por su contribución!',
          dashboardMessage:
            'Por favor, visite su Panel de Control si quiere ver lo que sigue',
        },
        error: {
          title: 'Hubo un conflicto con su envío.',
          content:
            'Lo sentimos, parece que la página de la encuesta que está intentando enviar ya ha sido enviada. Por favor, dirijase a su',
          dashboardLink: 'panel de control del estudio',
          contentExtra: 'donde podrá continuar donde lo dejó.',
          followUpTitle: 'Esta encuesta ya no está disponible.',
          followUpContent:
            'Por favor, compruebe su bandeja de entrada de correo electrónico para asegurarse de que está completando la encuesta con la fecha de hoy. Si cree que está viendo este mensaje por error, utilice el enlace "Contáctenos" de arriba para obtener ayuda.',
        },
      },
    },
    logIn: {
      heading: 'Acceder a {{study_name}}',
      content:
        'Inicie sesión con el nombre de usuario y la contraseña que creó durante el registro.',
      description: '¿Aún no tienes cuenta? ',
      signUp: 'Crear cuenta',
      noProvider:
        'Ningún proveedor de identidad ha sido configurado. Si el problema persiste, por favor contacte a soporte técnico.',
    },
    signUp: {
      codeReSent: 'Hemos enviado un nuevo código de verificación',
      heading: 'Comencemos',
      content: 'Solo necesitamos recolectar algunos datos antes de comenzar.',
      description: '¿Ya tienes cuenta? ',
      login: 'Inicia Sesión',
      form: {
        phoneNumber: 'Número de Télefono',
        password: 'Contraseña',
        confirm: 'Confirmar Contraseña',
        submit: 'Crear cuenta',
        email: 'Dirección de correo eléctronico',
        code: 'Código',
        code_submit: 'Continuar',
        confirmation_resend_text: 'No lo recibiste?',
        confirmation_resend_link: 'Reenviar código',
      },
    },
    forgotPassword: {
      codeSent: 'Hemos enviado un código de verificación',
      heading: 'Olvidaste tu contraseña?',
      willSendACode:
        'Enviaremos un código de verificación a tu correo eléctronico.',
      sendCode: 'Enviar código de verificación',
      success: 'Has actualizado tu contraseña exitosamente',
      return: 'Regresar a la página de inicio de sesión',
      resend: 'Reenviar código',
      reset: 'Restrablecer contraseña',
      codeLabel: 'Código',
      passwordLabel: 'Nueva contraseña',
      confirmPasswordLabel: 'Confirma tu nueva contraseña',
      instructions: 'Ingresa el código de verificacón y tu nueva contraseña',
      emailLabel: 'Correo Eléctronico',
      errorMessage:
        'Algo salió mal, por favor solicita un nuevo código de verificación',
      required: 'Este campo es requerido',
    },
    oneClickContribution: {
      components: {
        error: {
          header: '¡Espera! ¡Puede que no hayas terminado!',
          contactMessage:
            'Si cree que ha llegado a este mensaje por error, póngase en contacto con nosotros para obtener ayuda.',
          expiredMessage: 'Ha hecho clic en un enlace expirado.',
          internalErrorMessage: 'Ha ocurrido un error.',
          changedResponseHeader: '¡Gracias por responder!',
          changedResponseMessage:
            'Ya hemos registrado su respuesta anterior y solo podemos registrar una respuesta por encuesta.\n\nSi desea enviar una respuesta diferente, comuníquese con nuestro equipo de soporte haciendo clic en "Contáctenos".',
        },
      },
    },
    verifyIdentity: {
      components: {
        failurePage: {
          title:
            'Lamentablemente, no podemos verificar la información proporcionada.',
          confirmText:
            'Comuníquese con nuestro <strong>Equipo de soporte</strong> si tiene preguntas adicionales.',
          contactButton: 'Contáctenos',
          errorMessage:
            'Algo salió mal con tu solicitud. Por favor, inténtelo de nuevo más tarde.',
        },
        introPage: {
          title: 'Verificación de Identidad',
          intro:
            '<p>En este paso, verificaremos su información y es posible que le enviemos hasta dos mensajes de texto (esto puede generar cargos adicionales por parte de su proveedor de servicios telefónicos).</p><p>Cuando esté listo, presione <strong>Continuar</strong> a continuación. Comuníquese con nuestro <strong>Equipo de soporte</strong> si tiene preguntas adicionales.</p>',
          buttonContinue: 'Continuar',
          buttonContact: 'Contáctenos',
        },
        otpPage: {
          title: 'Verificación de dos pasos',
          defaultMessage:
            'No podemos verificar su código, intente nuevamente haciendo clic en Reenviar a continuación.',
          body: {
            enterCode: 'Si no recibió el código de acceso, presiona',
            resend: 'Reenviar',
            below: 'abajo.',
          },
          buttonContinue: 'Continuar',
          buttonResend: 'Reenviar',
          placeholder: 'Por favor introduzca el código',
        },
        successPage: {
          title: '¡Felicidades!',
          confirmText:
            'Hemos verificado sus datos con éxito. Presione el botón de abajo para continuar con el estudio.',
          errorMessage:
            'Algo saló mal con su petición. Por favor vuelva a intentar más tarde.',
        },
      },
    },
    enrollment: {
      verifyEmail: {
        title: 'Verifique su dirección de email',
        emailSent:
          'Le hemos enviado un email a la dirección que proporcionó. Si no lo recibió,',
        clickHere: 'de click aquí para reenviarlo.',
        emailSentRecently:
          'Se envió un email recientemente, por favor espere unos minutos y vuelva a intentarlo.',
      },
      verifyPhone: {
        title: 'Verificación de número telefónico',
        confirmText: 'Enviamos un código a',
        placeHolder: 'Introduzca el código de verificación',
        messageSent: '¿No lo recibió? ',
        resend: 'Reenviar',
        verifyOtp: 'Verificar',
        otpInvalid: 'Código inválido, por favor intente nuevamente',
      },
    },
    settings: {
      tabs: {
        accountSettings: 'Ajustes de la Cuenta',
        studyDocuments: 'Documentos del Estudio',
        security: 'Seguridad',
        contactInfo: 'Información de Contacto',
      },
      dashboardWithdrawal: {
        dashboardWithdrawalLabel: 'Retirarse del Estudio',
        dashboardWithdrawalModalParagragh1:
          'Al retirarse, no podra participar más en este estudio. Sus datos de estudio recopilados anteriormente pueden continuar usándose y compartiendo para los fines establecidos en su documento de consentimiento informado. No será elegible para compensación y no podrá volver a unirse a este estudio.',
        dashboardWithdrawalModalReason:
          '¿Tiene alguna razón para retirarse? Haganosla saber',
        dashboardWithdrawalModalParagragh2:
          'Si tiene algún problema, ¡Siempre puede comunicarse con nosotros para obtener ayuda en lugar de eliminarse permanentemente de este estudio!',
      },
      changeEmail: {
        enterYourEmail: 'Escribe tu nuevo correo eléctronico.',
        confirmChange: 'Confirmar cambio',
        verificationEmailSent:
          'Acabamos de enviar un correo de confirmación a tu nuevo correo eléctronico. Revisa tu bandeja de entrada para verificarlo y terminar con el proceso.',
        errorMessage:
          'Hubo un problema al actualizar su correo electrónico, contáctenos para obtener más ayuda.',
        defaultMessage:
          'Si tiene algún problema, siempre puede ponerse en contacto con nosotros para obtener ayuda.',
        securityWarning:
          'Por razones de seguridad, contáctenos directamente si desea cambiar su dirección de correo electrónico durante el estudio.',
        securityMessage:
          'Estaremos encantados de actualizar esa información por usted.',
        changeEmailAddress: 'Cambiar correo eléctronico',
        changeEmailAddressHead: 'Correo Eléctronico',
        changeEmailAddressTitle: 'Cambiar tu correo eléctronico',
        accountSettings: 'Ajustes de cuenta',
        accountInformation: 'Información de cuenta',
        editYourInformation:
          'Editar su correo eléctronico, contraseña u otro ajuste de su cuenta.',
      },
      changePhoneNumber: {
        changePhoneNumber: 'Número de teléfono',
        changePhoneNumberHead: 'Cambiar número de teléfono',
        changePhoneNumberTitle: 'Cambiar su número de teléfono',
      },
      changePassword: {
        confirmOldPassword:
          'Por favor, confirme su contraseña anterior y cree su contraseña nueva.',
        noteImpactStudies:
          'Nota: esto afectará cualquier otro estudio en el que esté inscrito y su membresía en {{company_name}} App.',
        confirmChange: 'Confirmar Cambio',
        issuesMessage:
          'Si tiene algún problema, puede contactarnos para obtener ayuda.',
        oldPassword: 'Contraseña antigua incorrecta',
        choosePassword: 'Elija una nueva contraseña.',
        passwordChangeSuccessfully: 'Su contraseña fue cambiada con éxito.',
        passwordChangeError:
          'Hubo un problema al cambiar su contraseña. Póngase en contacto con soporte.',

        changePassword: 'Contraseña',
        changePasswordHead: 'Cambiar Contraseña',
        changePasswordTitle: 'Cambie su contraseña ',
        oldPasswordPlaceholder: 'Intrduzca su contraseña actual',
        newPasswordPlaceholder: 'Introduzca la nueva contraseña',
        confirmNewPasswordPlaceholder: 'Confirme la nueva contraseña',
      },
      security: {
        title: 'Seguridad',
        subtitle: 'Dispositivos dónde se ha iniciado sesión',
        text: 'Actualmente cuenta con una sesión activa en los siguientes dispositivos.',
        browser: 'Navegador',
        logOut: 'Cerrar sesión',
        thisDevice: 'Este dispositivo',
        unknownDevice: 'Dispositivo desconocido',
        autoLogout:
          'Cierre de sesión automático en todos los dispositivos después de 30 minutos de inactividad',
        onToggle: 'ON',
        offToggle: 'OFF',
      },
      contactInfo: {
        blurb:
          '<p>Las siguientes preguntas le pedirán que nos proporcione información personal básica, incluida la información de contacto.</p><p>Esta información proporcionada se utilizará para contactarlo y actualizarlo durante su participación en el estudio.</p>',
        edit: 'Editar',
        submit: 'Enviar',
        cancel: 'Cancelar',
        participantInformation: 'Información del participante',
        genericError: 'Algo salió mal, por favor inténtelo de nuevo más tarde.',
        updateError:
          'Algo salió mal mientras se actualizaba su información de contacto, por favor inténtelo de nuevo más tarde. ',
        contactInformation: 'Información del contacto',
        optionalTitle: 'Campos Opcionales a continuación',
      },
    },
    dashboard: {
      defaultHeader: 'Panel del Estudio',
      defaultDisplayGroup: 'Mis Actividades',
      completedDisplayGroup: 'Actividades Completadas',
      noContributionsAvailable:
        'Actualmente no hay contribuciones disponibles para este estudio.',
      confirm: 'Confirmar',
      cancel: 'Cancelar',
      contributionModal: {
        header: 'Contribución',
      },
      confirmModal: {
        pleaseConfirm: 'Confirme por favor',
        stillAvailableForPreviousDay:
          'Todavía está disponible, pero es para un día anterior.',
        areYouSure: '¿Estás seguro de que quiere hacer esto?',
      },
      asyncActionDefaultErrorMessage:
        'Algo salió mal al cargar esta contribución. Por favor, inténtalo más tarde.',
      thankYou: {
        completeDailySurvey:
          'Gracias por completar su encuesta diaria y contribuir al Estudio de Experiencias COVID-19.',
        contribution:
          'Su contribución es un paso significativo hacia la comprensión de COVID-19 y sus efectos en el mundo real. Por favor, no dude en',
        contactUs: 'contáctanos',
        questions: 'Si tienes alguna pregunta.',
        information:
          'Para más información sobre COVID-19, por favor consulta al Centro de Control y Prevención de Enfermedades:',
        seeYou: '¡Nos vemos mañana en la encuesta diaria!',
      },
      progressTracker: {
        completed: 'Sus Actividades Completadas',
        remaining: 'Actividades Pendientes',
      },
    },
  },
  components: {
    common: {
      contactUs: 'Contáctanos',
      submit: 'Enviar',
      continue: 'Continuar',
      returnStudyDashboard: 'Volver al Panel del Estudio',
      logIn: 'Iniciar Sesión.',
      returnDashboard: 'Volver al Panel',
      yes: 'Si',
      no: 'No',
      months: {
        January: 'Enero',
        February: 'Febrero',
        March: 'Marzo',
        April: 'Abril',
        May: 'Mayo',
        June: 'Junio',
        July: 'Julio',
        August: 'Agosto',
        September: 'Septiembre',
        October: 'Octobre',
        November: 'Noviembre',
        December: 'Diciembre',
      },
    },
    billboardNavigation: {
      submitLabel: 'Enviar',
      nextLabel: 'Continuar',
      backLabel: 'Regresar',
      previousLabel: 'Pregunta anterior',
      declineLabel: 'Declinar',
      withdrawLabel: 'Retirarse del Estudio',
    },
    blackfynn: {
      emailPlaceholder: 'Introduzca la dirección de correo electrónico',
    },
    reauthenticateModal: {
      title: 'Confirme su cuenta',
      content:
        'Por favor, confirme su firma en este documento. Reingrese su información de inicio de sesión.',
      facebook: 'Continuar con Facebook',
    },
    contactModal: {
      header: {
        success: '¡Gracias!',
        error: 'Aquí hay un problema',
        default: '¿Necesitas ayuda? Ponte en contacto con nosotros.',
      },
      message: {
        success: 'Lo contactaremos lo más pronto posible.',
        error: 'Ha ocurrido un error desconocido.',
        default:
          'Si tiene preguntas o tiene algún problema durante el estudio, por favor contáctenos. ¡Estamos para ayudar!',
      },
      body: {
        form: {
          emailLabel: 'Dirección de correo electrónico',
          requestLabel: 'Describa su petición',
        },
        preferredContactMethod: 'Método de contacto preferido',
        email: 'Correo electrónico',
        phone: 'Teléfono',
        callSupport:
          'Llame al teléfono {{supportNumber}} para contactar a nuestro soporte',
        sendRequest: 'Enviar petición',
        contactModalHeading:
          '¿Necesitas ayuda?  Ponte en contacto con nosotros.',
      },
    },
    cookieBanner: {
      buttonText: 'ACEPTAR',
      bannerText:
        'Usamos cookies o tecnologías similares para grabar sus preferencias y realizar análisis. Para conocer nuestras políticas de privacidad, la información que recopilamos y cómo la usamos, consulte nuestro ',
      noticeOfCollection: 'Aviso en la Colección',
    },
    datePicker: {
      day: 'Día',
      year: 'Año',
      month: 'Mes',
    },
    error: {
      errorOccurred: 'Un Error ha ocurrido',
      serverError:
        'Algo salió mal de nuestra parte. Intente actualizar la página, si el problema persiste, póngase en contacto con el soporte.',
      somethingWrong:
        '<p>Hola, parece que algo salió mal de nuestra parte. Actualice su página para volver a intentarlo.</p><p>Si el problema persiste, póngase en contacto con soporte.</p>',
      defaultError:
        'Algo salió mal de nuestra parte. Intente actualizar la página, si el problema persiste, póngase en contacto con el soporte.',
      contributionDoesNotExist: 'Esta contribución ya no existe',
      contributionDoesNotExistMessage:
        'Parece que la contribución que seleccionaste ya no está disponible.',
    },
    footer: {
      defaultCopyright: 'Achievement es una marca de Evidation',
      termsConditions: 'Términos y Condiciones',
      privacyPolicy: 'Política de privacidad',
    },
    header: {
      links: {
        contactUs: 'contáctenos',
        dashboard: 'panel de control',
        faq: 'preguntas frecuentes',
        home: 'inicio',
        login: 'iniciar sesión',
        logout: 'cerrar sesión',
        settings: 'ajustes',
      },
      languagePickerModal: {
        title: 'Seleccione su idioma preferido',
      },
      eligibilityButton: 'VERIFICAR ELEGIBILIDAD',
      mobileMenuButton: 'Menú',
      closeMenuButton: 'Cerrar menú',
      startButton: 'COMIENZO',
      textSizeSelect: {
        aPlus: 'A+',
        small: 'Pequeño',
        medium: 'Mediano',
        large: 'Grande',
        pt: 'pt',
      },
      skipToContent: 'Continuar al contenido principal',
    },
    reauthLoginForm: {
      emailPlaceholder: 'Introduzca la dirección de correo electrónico',
      passwordPlaceholder: 'Introduzca su contraseña de {{company_name}}',
      forgotPassword: '¿Ha olvidado su contraseña?',
    },
    loginForm: {
      heroButton: 'Iniciar sesión',
      forgotPassword: '¿Ha olvidado su contraseña?',
      emailLabel: 'Correo Electrónico',
      passwordLabel: 'Contraseña',
    },
    multifactorAuth: {
      sendVerificationButton: 'Enviar el código de verificación',
      authenticationSuccess: 'Autenticación Exitosa',
      resendVerificationCode: 'Reenviar el código de verificación',
    },
    emailVerification: {
      verificationSuccess:
        'Su correo electrónico ha sido verificado exitosamente',
      verificationProblem:
        'Hubo un problema al verificar su correo electrónico. Póngase en contacto con soporte técnico para obtener ayuda con este problema.',
      emailTitleSuccess: 'Correo electrónico verificado',
      emailTitleError: 'Error de verificación de correo electrónico',
    },
    fourOhFour: {
      greetings:
        'Hola, parece que algo salió mal de nuestra parte. Actualice su página para volver a intentarlo.',
      contactSupport:
        'Si el problema persiste, póngase en contacto con el soporte.',
      titleText: 'Ocurrió un error',
    },
    camCogSuccess: {
      successMessage: 'Prueba completada',
      errorMessage: 'Error',
      errorParagraph:
        'Hubo un problema al guardar la finalización de su prueba. Póngase en contacto con el soporte para obtener ayuda con este problema.',
    },
    education: {
      checkEligibility: {
        alreadyArchievementAccount: '¿Ya tiene cuenta en {{company_name}}?',
      },
      dataCloseLockMessage:
        'Gracias por su interés. Ya hemos concluido el reclutamiento para este estudio. Por favor contáctenos en caso de tener más preguntas.',
    },
    notificationModal: {
      congratulations: '¡Felicidades!',
      oops: '¡Ups!',
      errorIcon: 'Icono de error',
    },
    authExpirationModal: {
      youWillBeLoggedOutIn: 'Será desconectado en',
      pleaseChoose: 'Por favor elija permanecer conectado o cerrar sesión',
      sessionAboutToExpire: '¡Su sesión esta a punto de expirar!',
      logOut: 'Cerrar Sesión',
      stayLoggedIn: 'Permanecer Conectado',
      seconds_one: 'segundo',
      seconds_other: 'segundos',
    },
    sessionExpiredBanner: {
      loggedOutDueToInactivity:
        'Usted ha sido desconectado automáticamente debido a la inactividad; Por favor inicie sesión.',
    },
    reConsentModal: {
      header: 'Por favor vuelva a dar su consentimiento para este estudio',
      body: 'Gracias por su participación. Hemos actualizado el formulario de consentimiento informado (ICF), el cual había firmado anteriormente; Para seguir participando necesitamos que firme la versión actualizada. Haga clic en Continuar para comenzar.',
      button: 'Continuar',
    },
    compensation: {
      viewMore: 'Ver Más',
      viewLess: 'Ver Menos',
      learnMore: 'Más información',
    },
  },
  validations: {
    required: 'Por favor selecciona día, mes y año',
    passwordAsyncValidate: {
      passwordsNotMatch: 'Tus contraseñan no son iguales',
      errorMessage: 'No hemos podido validar tu contraseña',
    },
  },
};
