export const enUS = {
  routes: {
    contribution: {
      declineLabel: 'I Decline to Sign this Document',
      areYouSureExit: 'Are you sure you want to exit the survey?',
      answersWontBeSaved:
        "Your answers on this page won't be saved if you leave.",
    },
    ehr: {
      components: {
        introPage: {
          secureDataMessage:
            'Your electronic health data is secure. To learn more click ',
          introTipTitle: 'You will need: ',
        },
        findPage: {
          connectButtonText: 'Connect',
        },
      },
    },
    followUp: {
      components: {
        complete: {
          title: 'Thank you for your Contribution!',
          dashboardMessage:
            "Please visit your Dashboard if you want to see what's next",
        },
        error: {
          title: 'There was a conflict with your submission.',
          content:
            'Sorry, it looks like the survey page you are trying to submit was already submitted. Please continue to your',
          dashboardLink: 'project dashboard',
          contentExtra:
            'where you will be able to continue where you left off.',
          followUpTitle: 'This survey is no longer available.',
          followUpContent:
            'Please check your email inbox to ensure you are completing the survey with today\'s date. If you believe that you are seeing this message this in error, please use the "Contact Us" link above for  help.',
        },
      },
    },
    logIn: {
      heading: 'Log in to {{study_name}}',
      content:
        'Log in using the username and password you created during sign-up.',
      description: 'Don’t have an account? ',
      signUp: 'Create account',
      noProvider:
        'No identity providers configured. If the problem persists, please contact support.',
    },
    signUp: {
      codeReSent: 'We have sent a new confirmation code.',
      heading: 'Get started',
      content: 'We just need to collect some info to create your account.',
      description: 'Already have an account? ',
      confirmation_heading: 'Confirm Email',
      confirmation_text:
        'We’ve sent you a verification code to the email address you entered. Please enter it below to verify your email address.',
      login: 'Login',
      form: {
        phoneNumber: 'Phone Number',
        email: 'Email Address',
        password: 'Password',
        confirm: 'Confirm Password',
        code: 'Code',
        code_submit: 'Continue',
        confirmation_resend_text: "Didn't receive it?",
        confirmation_resend_link: 'Resend Code',
        submit: 'Create account',
      },
    },
    forgotPassword: {
      codeSent: 'We have sent a confirmation code.',
      heading: 'Did you for forgot your password?',
      willSendACode: 'We will send a verification code to your email address.',
      sendCode: 'Send Verification Code',
      success: 'You have successfully updated your password',
      return: 'Return to login',
      resend: 'Resend code',
      reset: 'Reset Password',
      codeLabel: 'Code',
      passwordLabel: 'New Password',
      confirmPasswordLabel: 'Confirm your new password',
      instructions: 'Enter your confirmation code and your new password',
      emailLabel: 'Email Address',
      errorMessage: 'Something went wrong, please request a code again',
      required: 'This field is required',
    },
    oneClickContribution: {
      components: {
        error: {
          header: 'Wait!! You may not be done!',
          contactMessage:
            'If you believe you have reached this message in error, please contact us for assistance.',
          expiredMessage: 'You have clicked on an expired link.',
          internalErrorMessage: 'An error has occurred.',
          changedResponseHeader: 'Thank you for responding!',
          changedResponseMessage:
            'We have already recorded your earlier response and can only record one response per survey.\n\nIf you would like to submit a different response, please contact our Support Team by clicking "Contact Us".',
        },
      },
    },
    screener: {
      someResponsesInvalid:
        'Looks like some responses are missing or are in an unacceptable format.',
      goToFirstQuestion: 'Go to first question.',
    },
    verifyIdentity: {
      components: {
        failurePage: {
          title:
            'Unfortunately, we are not able to verify the infomation you entered',
          confirmText:
            'Please reach out to our <strong>Support team</strong> with additional questions.',
          contactButton: 'Contact Us',
          suspendedButton: 'Go to Settings',
          errorMessage:
            'Something went wrong with your request. Please try again later.',
        },
        introPage: {
          title: 'ID Verification',
          intro:
            '<p>In this step we will verify your entries and may send you up to two text messages (this may incur additional charges from your phone service provider).</p><p>When ready, press <strong>Continue</strong> below. Please react out to our <strong>Support team</strong> with additional questions.</p>',
          buttonContinue: 'Continue',
          buttonContact: 'Contact Us',
        },
        otpPage: {
          title: 'Two-Step Verification',
          defaultMessage:
            'We cannot verify your code, please try again by clicking Resend below.',
          body: {
            enterCode: 'If you did not receive the passcode, press',
            resend: 'Resend',
            below: 'below.',
          },
          buttonContinue: 'Continue',
          buttonResend: 'Resend',
          placeholder: 'Please enter the passcode',
        },
        successPage: {
          title: 'Congratulations!',
          confirmText:
            'We have successfully verified your entries. Press the button below to continue the project.',
          errorMessage:
            'Something went wrong with your request. Please try again later.',
        },
      },
    },
    informedConsent: {
      progressLabel:
        'You have completed {{percentage}}% of the informed consent.',
    },
    enrollment: {
      verifyEmail: {
        title: 'Verify Your Email Address',
        emailSent:
          'We’ve just sent an email to the address you provided. If you did not receive it,',
        clickHere: 'click here to re-send.',
        emailSentRecently:
          'An email was sent recently, please wait a few minutes and try again.',
      },
      verifyPhone: {
        title: 'Phone Number Verification',
        confirmText: 'We sent a code to',
        placeHolder: 'Enter verification code',
        messageSent: "Didn't get it? ",
        resend: 'Resend',
        verifyOtp: 'Verify',
        otpInvalid: 'OTP Code invalid please try again',
        otpValid: 'Your phone number has been changed.',
        havingIssues:
          "If you're having any issues, you can always contact us for help.",
        contactUs: 'Contact Us',
      },
    },
    settings: {
      tabs: {
        accountSettings: 'Account Settings',
        studyDocuments: 'Participant Documents',
        security: 'Security',
        contactInfo: 'Contact Info',
      },
      dashboardWithdrawal: {
        dashboardWithdrawalLabel: 'Withdraw from Project',
        dashboardWithdrawalLink: 'Withdrawal',
        dashboardWithdrawalModalParagragh1:
          'By withdrawing, you will no longer be able to participate in this project. Your previously collected project data may continue to be used and shared for the purposes stated in your informed consent document. You will not be eligible for compensation and you will not be able to re-join this project.',
        dashboardWithdrawalModalReason:
          'Please select the most relevant reason for your withdrawal.',
        dashboardWithdrawalModalParagragh2:
          "If you're having any issues, you can always contact us for help instead of permanently removing yourself from this project!",
      },
      changeEmail: {
        enterYourEmail: 'Please enter your new email address.',
        confirmChange: 'Confirm Change',
        verificationEmailSent:
          'A verification email was sent to your new email address. Please check your inbox to verify your email and complete the update.',
        errorMessage:
          'There was a problem updating your email please contact us for further assistance.',
        defaultMessage:
          "If you're having any issues, you can always contact us for help.",
        securityWarning:
          "For security reasons, please contact us directly if you'd like to change your email address during the project.",
        securityMessage: "We'd be happy to update that information for you.",
        changeEmailAddress: 'Change',
        changeEmailAddressHead: 'Email Address',
        changeEmailAddressTitle: 'Change your email address',
        accountSettings: 'Account Settings',
        accountInformation: 'Account Information',
        editYourInformation:
          'Edit your email address, password, and other account information.',
      },
      changePhoneNumber: {
        changePhoneNumber: 'Change',
        changePhoneNumberHead: 'Phone Number',
        changePhoneNumberTitle: 'Change your phone number',
        enterPhoneNumber: 'Please enter your new phone number.',
        confirmChange: 'Confirm change',
        havingIssues:
          "If you're having any issues, you can always contact us for help.",
        contactUs: 'Contact Us',
        defaultErrorMessage:
          'There was a problem updating your phone number. Please contact support.',
        defaultSuccessMessage: 'Your phone number has been changed.',
      },
      changePassword: {
        confirmOldPassword:
          'Please confirm your old password, and create your new password.',
        noteImpactStudies:
          'Note: this will impact any other studies you are enrolled in and your membership on {{company_name}} App.',
        confirmChange: 'Confirm Change',
        issuesMessage:
          "If you're having any issues, you can always contact us for help.",
        choosePassword: 'Please choose a new password.',
        passwordChangeSuccessfully: 'Your password was changed successfully.',
        passwordChangeError:
          'There was a problem changing your password. Please contact support.',

        changePassword: 'Change',
        changePasswordHead: 'Password',
        changePasswordTitle: 'Change your password',
        oldPasswordPlaceholder: 'Enter current password',
        newPasswordPlaceholder: 'Enter new password',
        confirmNewPasswordPlaceholder: 'Confirm new password',
        oldPassword: 'Old Password',
        newPassword: 'New Password',
      },
      security: {
        title: 'Security',
        subtitle: 'Where you’re signed in',
        text: 'You’re currently signed in to your account on these devices.',
        browser: 'Browser',
        logOut: 'Log Out',
        thisDevice: 'This Device',
        unknownDevice: 'Unknown Device',
        autoLogout: 'Auto-Logout all devices after 30 minutes of inactivity',
        onToggle: 'ON',
        offToggle: 'OFF',
        onToggleTitle: 'Enable auto-logout',
        offToggleTitle: 'Disable auto-logout',
      },
      contactInfo: {
        blurb:
          '<p>The following questions will ask you to provide some basic personal information to us, including contact information.</p><p>This provided information will be used to contact and update you throughout your participation in the project.</p>',
        edit: 'Edit',
        submit: 'Submit',
        cancel: 'Cancel',
        participantInformation: 'Participant Information',
        genericError: 'Something when wrong. Please try again later.',
        updateError:
          'Something went wrong while updating your contact information. Please try again later.',
        contactInformation: 'Contact Information',
        optionalTitle: 'Optional Fields Below',
      },
    },
    dashboard: {
      defaultHeader: 'Project Dashboard',
      defaultDisplayGroup: 'My Activities',
      completedDisplayGroup: 'Completed Activities',
      noContributionsAvailable:
        'No contributions are currently available for this project.',
      confirm: 'Confirm',
      cancel: 'Cancel',
      contributionModal: {
        header: 'Contribution',
      },
      confirmModal: {
        pleaseConfirm: 'Please Confirm',
        stillAvailableForPreviousDay:
          'This is still available, but it’s for a previous day.',
        areYouSure: 'Are you sure you want to do this one?',
      },
      asyncActionDefaultErrorMessage:
        'Something went wrong loading this contribution. Please try again later.',
      thankYou: {
        completeDailySurvey:
          'Thank you for completing your daily survey and contributing to the COVID-19 Experiences Study.',
        contribution:
          'Your contribution is a meaningful step towards understanding COVID-19 and its effects in the real world. Please feel free to',
        contactUs: 'contact us',
        questions: 'if you have any questions.',
        information:
          'For more information regarding COVID-19, please refer to the Center for Disease Control and Prevention:',
        seeYou: "See you during tomorrow's daily survey!",
      },
      progressTracker: {
        completed: 'Your Completed Activities',
        remaining: 'Remaining Activities',
      },
    },
  },
  components: {
    common: {
      contactUs: 'Contact Us',
      submit: 'Submit',
      continue: 'Continue',
      returnStudyDashboard: 'Return to Project Dashboard',
      logIn: 'Log In.',
      returnDashboard: 'Return to Dashboard',
      yes: 'Yes',
      no: 'No',
      months: {
        January: 'January',
        February: 'February',
        March: 'March',
        April: 'April',
        May: 'May',
        June: 'June',
        July: 'July',
        August: 'August',
        September: 'September',
        October: 'October',
        November: 'November',
        December: 'December',
      },
      loading: 'Loading',
      expand: 'Expand',
    },
    billboardNavigation: {
      submitLabel: 'Submit',
      nextLabel: 'Continue',
      backLabel: 'Go Back',
      previousLabel: 'Previous question',
      declineLabel: 'Decline',
      withdrawLabel: 'Withdraw',
      progress: {
        youHaveCompleted:
          'You have completed {{percentage}}% of the questionnaire.',
        ofSection: 'of Section {{section}} of {{totalSections}}.',
        minutes: 'minutes',
        minutes_one: 'minute',
      },
    },
    blackfynn: {
      emailPlaceholder: 'Enter Email Address',
      errors: {
        defaultMessage:
          'Something went wrong looking up that account. Please try again or contact support.',
        accountExists:
          'Our records indicate that you are an existing participant. Please click <Link>Login</Link> to continue.',
      },
    },
    reauthenticateModal: {
      title: 'Confirm Your Account',
      content:
        'Please confirm your signature on this document. Please re-enter your login information.',
      facebook: 'Continue with Facebook',
    },
    contactModal: {
      header: {
        success: 'Thanks!',
        error: 'There was a problem',
        default: 'Need help? Get in touch.',
      },
      message: {
        success: "We'll contact you as soon as we can",
        error: 'An unknown error occurred',
        default:
          "If you have questions or run into any issues during the project, please contact us. We're here to help!",
      },
      body: {
        form: {
          emailLabel: 'Email Address',
          requestLabel: 'Describe Your Request',
        },
        preferredContactMethod: 'Your Preferred Contact Method',
        email: 'Email',
        phone: 'Phone',
        callSupport: 'Call {{supportNumber}} to contact our phone support',
        sendRequest: 'Send Request',
        contactModalHeading: 'Need help? Get in touch.',
      },
    },
    cookieBanner: {
      buttonText: 'ACCEPT',
      bannerText:
        'We use cookies or similar technologies to remember your preferences and conduct analytics. To learn about our privacy practices, including the information we collect and how we use it, please see our ',
      noticeOfCollection: 'Notice at Collection',
    },
    datePicker: {
      day: 'day',
      year: 'year',
    },
    error: {
      errorOccurred: 'An error occurred',
      serverError:
        'Something went wrong on our end. Please try refreshing the page, if the problem persists contact support.',
      somethingWrong:
        '<p>Hi there, it looks like something went wrong on our end. Please refresh your page to try again.</p><p>If the problem persists, please contact support.</p>',
      defaultError:
        'Something went wrong on our end. Please try refreshing the page, if the problem persists contact support.',
      contributionDoesNotExist: 'This contribution does not exist',
      contributionDoesNotExistMessage:
        'It looks like the contribution you selected is no longer available.',
    },
    footer: {
      defaultCopyright: 'Achievement is an Evidation Brand',
      termsConditions: 'Terms & Conditions',
      privacyPolicy: 'Privacy Policy',
    },
    header: {
      links: {
        contactUs: 'contact us',
        dashboard: 'dashboard',
        faq: 'FAQ',
        home: 'home',
        login: 'login',
        logout: 'log out',
        settings: 'settings',
      },
      languagePickerModal: {
        title: 'Select your preferred language',
      },
      eligibilityButton: 'CHECK ELIGIBILITY',
      startButton: 'START',
      mobileMenuButton: 'Menu',
      closeMenuButton: 'Close menu',
      textSizeSelect: {
        aPlus: 'A+',
        small: 'Small',
        medium: 'Medium',
        large: 'Large',
        pt: 'pt',
      },
      skipToContent: 'Skip to main content',
    },
    reauthLoginForm: {
      emailPlaceholder: 'Enter Email Address',
      passwordPlaceholder: 'Enter Your {{company_name}} Password',
      forgotPassword: 'Forgot your password?',
    },
    loginForm: {
      heroButton: 'Log in',
      forgotPassword: 'Forgot Password?',
      emailLabel: 'Email Address',
      passwordLabel: 'Password',
    },
    multifactorAuth: {
      sendVerificationButton: 'Send Verification Code',
      authenticationSuccess: 'Authentication Success',
      resendVerificationCode: 'Resend Verification Code',
    },
    emailVerification: {
      verificationSuccess: 'Your email has successfully been verified',
      verificationProblem:
        'There was a problem verifying your email. Please contact support for help with this issue.',
      emailTitleSuccess: 'Email Verified',
      emailTitleError: 'Email Verification Error',
    },
    fourOhFour: {
      greetings:
        'Hi there, it looks like something went wrong on our end. Please refresh your page to try again.',
      contactSupport: 'If the problem persists, please contact support.',
      titleText: 'An error occurred',
    },
    camCogSuccess: {
      successMessage: 'Test completed',
      errorMessage: 'Error',
      errorParagraph:
        'There was a problem saving your test completion. Please contact support for help with this issue.',
    },
    education: {
      checkEligibility: {
        prompt: 'Check your eligibility in the project to enroll.',
        alreadyArchievementAccount:
          'Already have {{aOrAn}} {{company_name}} account?',
      },
      dataCloseLockMessage:
        "Thanks for your interest. We've closed recruitment for this project. Please reach out to us if you have any questions.",
    },
    notificationModal: {
      congratulations: 'Congratulations!',
      oops: 'Oops!',
      errorIcon: 'Error Icon',
    },
    authExpirationModal: {
      youWillBeLoggedOutIn: 'You will be logged out in',
      pleaseChoose: 'Please choose to stay logged in or log out.',
      sessionAboutToExpire: 'Your session is about to expire!',
      logOut: 'Log Out',
      stayLoggedIn: 'Stay Logged In',
      seconds_one: 'second',
      seconds_other: 'seconds',
    },
    sessionExpiredBanner: {
      loggedOutDueToInactivity:
        'You have been automatically logged out due to inactivity. Please sign in.',
    },
    reConsentModal: {
      header: 'Please re-consent this project',
      body: 'Thank you for your participation. We’ve made some updates to the informed consent form (ICF) you had previously signed. To continue participating, we’ll need you to sign an updated ICF. Click Continue below to get started.',
      button: 'Continue',
    },
    compensation: {
      viewMore: 'View More',
      viewLess: 'View Less',
      learnMore: 'Learn more',
    },
    optOutModal: {
      withdrawalPossibleAnswers: {
        timeCommitment: 'Time commitment was beyond what was anticipated',
        studyActivities:
          'Too many project activities to complete (i.e. surveys, biospecimen collection, wearable device requirements)',
        activitiesComplicated:
          'Project activities are too complicated (e.g. shipping, biosample collection, device set-up)',
        anotherStudy: 'Participating in another project',
        lackOfFeedback: 'Lack of feedback on project progress',
        enoughCompensation: 'Not enough compensation provided',
        notSharingData: 'Not comfortable sharing data',
        interestedParticipating: 'No longer interested in participating',
        other: 'Other reason not listed',
        notSay: 'Prefer not to say',
      },
      successMessage: 'You have withdrawn from the project!',
      errorMessage: 'There was a problem. Please contact support.',
    },
    tooltipButton: {
      showTooltip: 'Show tooltip',
    },
  },
  validations: {
    required: 'Please select a day, month and year.',
    passwordAsyncValidate: {
      passwordsNotMatch: 'Your passwords do not match',
      errorMessage: 'Unable to validate password',
    },
  },
};
