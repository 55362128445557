"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.enUS = void 0;
var enUS = exports.enUS = {
  common: {
    errorMessage: 'An error has occurred',
    months: {
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December'
    },
    today: 'Today',
    weekDayLong: {
      Sunday: 'Sunday',
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday'
    },
    weekDayShort: {
      Sunday: 'Su',
      Monday: 'Mo',
      Tuesday: 'Tu',
      Wednesday: 'We',
      Thursday: 'Th',
      Friday: 'Fr',
      Saturday: 'Sa'
    },
    dateFormats: {
      year: 'YYYY',
      yearMonth: 'YYYY-MM',
      monthDayYear: 'MM-DD-YYYY',
      yearMonthDay: 'YYYY-MM-DD'
    },
    languages: {
      English: 'English',
      French: 'French',
      German: 'German',
      Italian: 'Italian',
      Spanish: 'Spanish'
    },
    clear: 'Clear',
    yes: 'Yes',
    no: 'No',
    other: 'Other',
    preferNotAnswer: 'I prefer not to answer',
    male: 'Male',
    female: 'Female',
    nonBinary: 'Non-binary / Gender Queer',
    transgenderFemale: 'Transgender Female/Trans Woman/MtF',
    transgenderMale: 'Transgender Male/Trans Man/FtM',
    genderNonListed: 'A gender not listed here',
    intersex: 'Intersex',
    noneDescribeMe: 'None of these describe me'
  },
  components: {
    deleteButton: {
      confirmation: 'Are you sure you want to delete this?'
    },
    address: {
      autofillError: 'Please select your verified address from the drop down below. Ensure you do not use autofill as this can be incorrect compared to the address listed. Selection of your address from the drop down is a requirement to proceed with the eligibility steps.'
    },
    uploader: {
      removeHeading: 'Remove this Image?',
      removeImage: 'Remove Image',
      cancel: 'Cancel',
      numberOfFilesError: 'Files not uploaded due to number of files. Please upload up to {{MaxFiles}} photos and try again.',
      uploadRules: 'Files must be under {{formattedBytes}} and in {{extensions}} format. PDF files will not be accepted.'
    },
    checkboxGroup: {
      noneAbove: 'None of the above'
    },
    connectedFile: {
      imageError: 'There was a problem with your image, please try again.',
      enterUrl: 'Please enter url to image',
      processing: 'Processing',
      bytes: 'bytes',
      clickSelect: 'Click to select file or drop a file here. Accepted file formats:',
      clickSelectUrl: 'Click to add image URL',
      deleteConfirm: 'Are you sure you want to delete this?',
      preview: 'Preview',
      clear: 'Clear'
    },
    datePicker: {
      month: 'Month',
      day: 'Day',
      year: 'Year'
    },
    fieldHeight: {
      feet: 'Feet',
      inch: 'Inches'
    },
    insuranceProviderId: {
      idImage: 'Humana Id Card',
      medicareId: 'Medicare Advantage Identification Number',
      reenter: 'Please re-enter',
      notMember: 'I am not currently a Humana Medicare Advantage member.'
    },
    password: {
      password: 'Create Password',
      confirm: 'Confirm',
      validating: 'Validating',
      ok: 'Ok'
    },
    progressBar: {
      estimation: 'On average, it takes about {{time}} to complete.',
      minutes_one: '{{count}} minute',
      minutes_other: '{{count}} minutes',
      percentage: '<span id="t-progressValue">{{progress}}%</span> of the sign-up process has been completed.'
    },
    rangeSlider: {
      maxMinEntryError: 'please ensure Maximum length of entry is greater than Minimum length of entry'
    },
    select: {
      placeholder: 'Select one from the list'
    },
    selectAdvanced: {
      placeholderSingle: 'Select one from the list',
      placeholderMultiple: 'Select at least one from the list'
    },
    error: {
      errorOccurred: 'An error occurred',
      somethingWrong: '<p>Hi there, it looks like something went wrong on our end. Please refresh your page to try again.</p><p>If the problem persists, please contact support.</p>',
      defaultError: 'Something went wrong on our end. Please try refreshing the page, if the problem persists contact support.',
      contributionDoesNotExist: 'This contribution does not exist',
      contributionDoesNotExistMessage: 'It looks like the contribution you selected is no longer available.'
    }
  },
  fields: {
    age: {
      standardLabel: 'What is your current age (in years)?'
    },
    dob: {
      yearOfBirth: 'What is your year of birth?',
      monthYearBirth: 'What is your month and year of birth?',
      dateOfBirth: 'What is your date of birth?'
    },
    emailConfirm: {
      enterEmail: 'Please enter an email address',
      reEnterEmail: 'Please re-enter that same email address'
    },
    english: {
      label: 'Are you able to read, speak, and understand English?'
    },
    ethnicity: {
      label: 'Which racial or ethnic group(s) do you identify with? Please select all that apply.',
      americanOrAlaskan: 'American Indian or Alaskan Native',
      asian: 'Asian',
      blackOrAfrican: 'Black or African American',
      hispOrLat: 'Hispanic, Latino, or Spanish origin',
      eastOrNorthAfrican: 'Middle Eastern or North African',
      hawaiianOrIslander: 'Native Hawaiian or Other Pacific Islander',
      notHispOrLat: 'Not Hispanic or Latino',
      white: 'White',
      anotherRace: 'Another race or ethnicity'
    },
    gender: {
      label: 'What is your current gender? Please select all that apply.'
    },
    heightInternational: {
      enterHeight: 'Enter height',
      enterHeightCentimeters: 'Enter height in centimeters',
      imperial: 'Imperial',
      metric: 'Metric',
      centimeters: 'Centimeters'
    },
    weightInternational: {
      imperialWeight: 'Enter weight in pounds',
      stoneWeight: 'Enter weight in stones',
      metricWeight: 'Enter weight in kilograms',
      pounds: 'Pounds',
      stones: 'Stones',
      kilograms: 'Kilograms',
      errorMsg: 'Please enter a value between {{min}} {{unit}} and {{max}} {{unit}}.',
      errorMsgKgMinMax: 'You must give us your weight without shoes/heavy clothing in kilograms between {{min_kg}} and {{max_kg}} kg, using up to 1 decimal place. Please use a decimal point not a comma',
      errorMsgPoundsMinMax: 'You must give us your weight without shoes/heavy clothing in pounds, using only numbers between {{min_pounds}} and {{max_pounds}} pounds.',
      errorMsgStonesMinMax: 'You must give us your weight without shoes/heavy clothing in stones, using only numbers between {{min_stones}} and {{max_stones}} stones and {{min_pounds}} and {{max_pounds}} pounds, If your weight is an exact number of stones and no pounds, please type ‘0’ on this field'
    },
    languageIntl: {
      label: 'Are you able to read, speak, and understand {{language}}?'
    },
    fileUpload: {
      uploadingFiles: 'Uploading files',
      numberFiles_one: '{{count}} file',
      numberFiles_other: '{{count}} files',
      formatFileNumError: 'Number of files selected exceeds the maximum allowed. The first {{number}} of the selected files have been added.',
      fileSizeError: 'Please confirm that each file is less than {{size}} and in JPEG or PNG format.',
      selectDropFile: 'Click to select file or drop up to {{maxFileCount}}  here.',
      formatAccepted: 'Accepted file formats: {{formatAccept}}',
      uploadAFile: 'Please upload at least one file.'
    },
    sex: {
      label: 'What sex were you assigned at birth (for example, what was initially listed on your birth certificate)?'
    },
    countryResident: {
      questionLabel: 'Do you currently live in {{country}}?',
      US: 'the US',
      UK: 'the UK',
      Germany: 'Germany',
      France: 'France',
      Canada: 'Canada',
      Italy: 'Italy',
      Spain: 'Spain'
    },
    usResident: {
      questionLabel: 'Do you currently live in the United States?'
    },
    pregnancy: {
      questionLabel: 'Are you currently pregnant?',
      currentlyPregnant: 'Yes, I am currently pregnant',
      planningToBePregnant: 'Yes, I am planning to become pregnant in the next year',
      dontKnow: 'I don’t know'
    }
  },
  validations: {
    required: 'Please enter a response',
    requiredCheckbox: 'You must select at least one.',
    greaterThanZero: 'Enter value greater than 0.',
    invalidEmail: 'Your email address does not appear to be valid',
    alphaNumericError: 'This field can only contain letters and numbers.',
    lengthError: {
      max: 'This field must contain no more than {{max}} characters (current length {{length}})',
      min: 'This field must contain at least {{min}} characters (current length {{length}})'
    },
    textOnly: 'This field can only contain letters',
    phoneError: 'This field must be in the format of 12345678999 or 1 (234) 567-8999',
    dateValidation: 'Please select a day, month and year.',
    timeValidation: 'Please use the format 11:11 AM',
    zipValidation: 'US zipcode must contain 5 digits.',
    invalidJson: 'File has invalid JSON format',
    invalidFileType: '{{fileName}} is an invalid file type. The accepted file format is: {{acceptedTypes}}',
    invalidFileTypes: '{{fileName}} is an invalid file type. The accepted file formats are: {{acceptedTypes}}',
    noEmptyArray: 'Files not uploaded due to number of files. Please upload up to {{maxFiles}} photos and try again.',
    maxFilesUpload: 'You have selected too many files.',
    maxFileSize: 'Files {{fileList}} exceeds the allowed file size.',
    humanaLengthValidation: 'Your Humana ID must be an H plus {{length}} numbers.',
    mustCheck: 'This must be checked to proceed.',
    bpValid: 'Please enter a valid blood pressure measurement',
    pleaseConfirmEmail: 'Please confirm you typed your email address correctly',
    datetime: {
      selectDateTime: 'Please select a date and time',
      validDate: 'Please enter a valid date',
      validTime: 'Please enter a valid time',
      validDateRange: 'Please enter a date in the range  {{startDate}} - {{endDate}}',
      validAfterDate: 'Please enter a date after {{validDate}}',
      validBeforeDate: 'Please enter a date before {{validDate}}',
      validTimeRange: 'Please enter a time in the range {{timeRange}}'
    },
    dob: {
      invalidDate: 'Invalid Date',
      invalidDateFormat: 'Invalid date format, expects {{format}}',
      cannotBeFuture: 'Date cannot be in the future.'
    },
    number: {
      minMax: 'Please respond with a number between {{min}} - {{max}}.',
      min: 'Please respond with a number greater than {{min}}',
      max: 'Please respond with a number less than {{max}}',
      decimal: 'Please respond with a number containing {{decimal}} decimal places (Ex.: X{{zeros}})'
    },
    heightInternational: {
      metricErrorMsg: 'Enter value 91 to 243 centimeters.',
      imperialErrorMsg: 'Please enter both feet and inches',
      customImperialMinMax: 'You must give us your height without shoes in feet and inches, using only numbers between {{min}} and {{max}} feet and {{minInches}} and {{maxInches}} inches. If your height is an exact number of feet and no inches, please select ‘0’ for this field.',
      customMetricMinMax: 'You must give us your height without shoes in centimeters, using only numbers between {{min}} and {{max}} centimeters'
    },
    password: {
      length: 'At least 12 characters in length',
      lowercase: 'At least 1 lowercase character',
      number: 'At least 1 number',
      specialCharacter: 'At least 1 special character',
      uppercase: 'At least 1 uppercase character'
    },
    checkboxWithNone: {
      minMaxOptions: 'Please select between {{min}} and {{max}} options.'
    },
    date: {
      minMax: 'Please select a date between {{min}} and {{max}}'
    }
  }
};