"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateMetricHeight = exports.validateImperialHeight = exports["default"] = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.number.constructor.js");
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _i18n = require("../../i18n");
var _excluded = ["name"],
  _excluded2 = ["name"];
// Validate that height is with in the same range that is used for imperial
// height measurement.
var validateMetricHeight = exports.validateMetricHeight = function validateMetricHeight(_ref) {
  var name = _ref.name,
    rest = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
  return function (value) {
    if (value && value["".concat(name, "_units")] === 'centimeters' && rest !== null && rest !== void 0 && rest.metric_min && rest !== null && rest !== void 0 && rest.metric_max) {
      var metric_min = rest.metric_min,
        metric_max = rest.metric_max;
      if ((value === null || value === void 0 ? void 0 : value[name]) >= Number(metric_min) && (value === null || value === void 0 ? void 0 : value[name]) <= Number(metric_max)) {
        return undefined;
      }
      return (0, _i18n.globalTranslate)('validations.heightInternational.customMetricMinMax', {
        min: Number(metric_min),
        max: Number(metric_max)
      });
    }
    return undefined;
  };
};

// Test to see if inches exists.
var validateImperialHeight = exports.validateImperialHeight = function validateImperialHeight(_ref2) {
  var name = _ref2.name,
    rest = (0, _objectWithoutProperties2["default"])(_ref2, _excluded2);
  return function (value) {
    if (value && value["".concat(name, "_units")] === 'inches' && !value[name]) {
      return (0, _i18n.globalTranslate)('validations.heightInternational.imperialErrorMsg');
    }
    if (value && value["".concat(name, "_units")] === 'inches' && value[name]) {
      if (rest !== null && rest !== void 0 && rest.imperial_feet_min && rest.imperial_feet_max && rest.imperial_inches_min && rest.imperial_inches_max) {
        if (Math.floor(value[name] / 12) >= Number(rest.imperial_feet_min) && Math.floor(value[name] / 12) <= Number(rest.imperial_feet_max) && value[name] >= Number(rest.imperial_feet_min) * 12 + Number(rest.imperial_inches_min) && value[name] <= Number(rest.imperial_feet_max) * 12 + Number(rest.imperial_inches_max)) {
          return undefined;
        }
        return (0, _i18n.globalTranslate)('validations.heightInternational.customImperialMinMax', {
          min: rest === null || rest === void 0 ? void 0 : rest.imperial_feet_min,
          max: rest === null || rest === void 0 ? void 0 : rest.imperial_feet_max,
          minInches: rest === null || rest === void 0 ? void 0 : rest.imperial_inches_min,
          maxInches: rest === null || rest === void 0 ? void 0 : rest.imperial_inches_max
        });
      }
    }
    return undefined;
  };
};
var HeightValidations = function HeightValidations(props) {
  return [validateMetricHeight(props), validateImperialHeight(props)];
};
var _default = exports["default"] = HeightValidations;