"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateWeight = exports["default"] = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.number.constructor.js");
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _i18n = require("../../i18n");
var _excluded = ["name"];
var validateWeight = exports.validateWeight = function validateWeight(_ref) {
  var name = _ref.name,
    rest = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
  return function (value) {
    if (value && value[name] && value["".concat(name, "_units")] === 'stones_as_pounds') {
      if (rest.stones_max && rest.stones_min && rest.stones_pounds_max && rest.stones_pounds_min) {
        if (value[name] > Number(rest.stones_max) * 14 + Number(rest.stones_pounds_max) || value[name] < Number(rest.stones_min) * 14 + Number(rest.stones_pounds_min)) {
          return (0, _i18n.globalTranslate)('fields.weightInternational.errorMsgStonesMinMax', {
            min_stones: rest.stones_min,
            min_pounds: rest.stones_pounds_min,
            max_stones: rest.stones_max,
            max_pounds: rest.stones_pounds_max
          });
        }
      }
      return undefined;
    }
    if (value && value[name] && value["".concat(name, "_units")] === 'pounds') {
      if (rest !== null && rest !== void 0 && rest.pounds_max && value[name] > Number(rest.pounds_max) || rest !== null && rest !== void 0 && rest.pounds_min && value[name] < Number(rest.pounds_min)) {
        return (0, _i18n.globalTranslate)('fields.weightInternational.errorMsgPoundsMinMax', {
          max_pounds: rest.pounds_max,
          min_pounds: rest.pounds_min
        });
      }
      return undefined;
    }
    if (value && value[name] && value["".concat(name, "_units")] === 'kilograms') {
      if (rest !== null && rest !== void 0 && rest.kg_max && value[name] > Number(rest.kg_max) || rest !== null && rest !== void 0 && rest.kg_min && value[name] < Number(rest.kg_min)) {
        return (0, _i18n.globalTranslate)('fields.weightInternational.errorMsgKgMinMax', {
          max_kg: rest.kg_max,
          min_kg: rest.kg_min
        });
      }
    }
    if (value && value[name] < 1) {
      return (0, _i18n.globalTranslate)('validations.greaterThanZero');
    }
    return undefined;
  };
};
var WeightValidations = function WeightValidations(props) {
  return [validateWeight(props)];
};
var _default = exports["default"] = WeightValidations;