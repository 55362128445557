// @ts-nocheck
import { Program } from './loadables/Program';
import * as L from './loadables';

import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { getUrlParams, pathContains } from './utils';
import {
  createCustomLinkTag,
  createCustomMetaTag,
  createCustomScriptTag,
  createCustomStyleTag,
} from './utils/createCustomTag';
import { logError } from './utils/errorLogger';

import ErrorBoundary from './errorBoundary';
import FourOhFour from './routes/404';
import LoadingIndicator from './components/loadingIndicator';
import MainLayout from './components/mainLayout';
import PropTypes from 'prop-types';
import Toastr from 'react-redux-toastr';
import _ from 'lodash';
import { actions } from './redux';
import api, { fetchEnrollmentLocalStorage, getIsWebview, storeEnrollmentLocalStorage } from './services';
import { connect } from 'react-redux';
import userAgentInfo from './utils/userAgentInfo';
import {
  EXTERNAL_AUTH_PROVIDER_ALLOWLIST,
  RECAPTCHA_VERSIONS,
  ENROLLMENT_STATUSES,
  STORAGE_PARTICIPANT_AUTH,
} from './constants';
import ParticipantAuthTokenExpirationHandler from './utils/authExpiration';
import ErrorPage from './components/error';
import VerifyPhoneModal from './components/OTP/verifyPhoneModal';
import { withTranslation } from 'react-i18next';
import { matchPath } from 'react-router';
import FailurePage from './routes/verify_identity/failurePage';
import type { Node } from './types/api';
import type { DynamicObject } from './types/utils';
import { Helmet } from 'react-helmet';

const filteredRoutes = [
  '/',
  '/screener',
  '/verify-email',
  '/login',
  '/log-in',
  '/informed_consent',
  '/terminal_state',
  '/withdrawn',
  '/unauthorized',
  '/404',
];

const noAuthRoutes = ['email-contribution'];

// Query parameters that we should always ignore
const ignoredQueryParams = [
  // These params are sent to us during third-party login and shouldn't
  // be used directly.
  'state',
  'code',
];

/**
 * Returns the result of `getUrlParams` but filters out any query
 * parameters in the `ignoredQueryParams` array.
 * @returns {object}
 */
const getFilteredUrlParams = () => {
  const params = getUrlParams(window.location.search);
  for (const name of ignoredQueryParams) {
    delete params[name];
  }
  return params;
};

const getStringError = (error) => {
  return typeof error === 'string' ? error : JSON.stringify(error);
};

export type EvaluateRoute = (newProps?: any) => void;
export type GetAllData = () => Promise<void>;
export type SubmitUserData = (
  userData: DynamicObject,
  transition?: boolean,
) => Promise<{ payload: any; error: string; errorMsg: Record<string, any>[] }>;
export type SubmitSurveyData = (
  payload: DynamicObject,
  transition?: boolean,
  node_slug?: string,
) => Promise<{ error: boolean; payload: any } | void>;
export type VerifiedSubmit = <T extends DynamicObject = DynamicObject>(
  payload: T,
  formAction: string | null,
  submitFunction: (
    payload: T & {
      form_action?: string;
      recaptcha_token?: string;
      otp_code?: string;
    },
  ) => any,
) => Promise<void>;
export type FetchData = () => Promise<void>;
export type GetCurrentNode = (slug: string) => Node | undefined;

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: ``,
      verifyParticipantData: false,
      apiError: null,
      otpVerify: false,
      otpCallback: null,
      otpPhoneNumber: '',
      otpError: null,
      otpIsLoading: false,
      otpSuccessMessage: '',
      stylesLoaded: false,
    };
    this.props.clearErrors();
    window.onpopstate = this.onBackButtonEvent;
    const cookieData = api.local.get(STORAGE_PARTICIPANT_AUTH);
    if (cookieData) {
      this.props.establishUser(cookieData);
    }
  }

  fetchData: FetchData = async () => {
    this.setIsLoading(true);
    //const storage = api.local.get(STORAGE_PARTICIPANT_AUTH) || {};
    const promises = [this.props.getLanding()];
    // TODO: Remove this code in a future sprint after we ensure its purpose is no longer needed.
    // From initial testing it seems unnecessassary
    //
    // if (this.isSurvey()) {
    //   const node_slug = this.props.location.pathname.split('/')[2];
    //   const urlParams = getFilteredUrlParams(window.location.search);
    //   promises.push(this.props.getSurvey(node_slug, urlParams));
    //
    //   this.props.setUser({
    //     enrollment_identifier: urlParams.enrollment_identifier,
    //     auth_token: urlParams.auth_token,
    //   });
    // }
    // } else if (
    //   storage.participant_auth_token &&
    //   storage.enrollment_identifier &&
    //   storage.slug
    // ) {
    //   this.props.getMeta(
    //     storage.participant_auth_token,
    //     storage.enrollment_identifier,
    //   );
    //   const { payload, error = false } = await this.props.getUser(
    //     storage.enrollment_identifier,
    //     storage.participant_auth_token,
    //   );
    //   console.log('fetchData:', error, this.props);
    //   if (!error) {
    //     storeEnrollmentLocalStorage({
    //       ...payload,
    //       // The backend doesn't return `is_webview`, so we need to override it.
    //       is_webview: storage.is_webview,
    //     });
    //   }
    // }

    const results = await Promise.all(promises);
    if (results.some((result) => result.error)) {
      // apiError object needed to know if the Error component will be rendered, throwing
      // and Error from here will not trigger the Errorboundary
      this.setState({ apiError: true });
    }
    if (!this.isCognitoRedirect()) {
      this.setIsLoading(false);
    }
  };

  // Allows components to refresh the enrollment profile within a "loading" context.
  refreshEnrollment: FetchData = async () => {
    this.setIsLoading(true);

    const storage = fetchEnrollmentLocalStorage();
    const { payload: user, error = false } = await this.props.getUser(
      storage.enrollment_identifier,
      storage.participant_auth_token,
    );
    if (error) {
      this.setState({ apiError: true });
    } else {
      storeEnrollmentLocalStorage({ ...user, slug: this.props.meta.slug });
    }

    this.setIsLoading(false);
  }

  isSurvey = () =>
    this.props.location.pathname &&
    this.props.location.pathname.split('/')[1] === 'surveys';

  /**
   * Tests whether we were redirected here from a Cognito login.
   * @returns {boolean}
   */
  isCognitoRedirect = () => {
    const queryParams = new URLSearchParams(this.props.location.search);
    // These two query params are ones that Cognito sends us as part of
    // the redirect. They are part of the OAuth standard so are unlikely
    // to change in the foreseeable future.
    return queryParams.has('code') && queryParams.has('state');
  };

  setIsLoading = (isLoading) => {
    this.setState({ loading: isLoading });
  };

  stylesLoadingInterval = undefined;

  async componentDidMount() {
    const { history } = this.props;
    const currentPath = history.location.pathname;
    const noAuthRoute = pathContains(currentPath, noAuthRoutes);

    const landing = async () => {
      try {
        await this.props.getLanding();
      } catch (e) {
        logError(e);
        this.setState({ apiError: true });
      } finally {
        this.setIsLoading(false);
      }
    };

    let fetchDataPromise: Promise<void> | null = null;
    if (noAuthRoute) {
      landing();
    } else if (this.props.prefetch) {
      fetchDataPromise = this.fetchData();
    } else {
      this.setIsLoading(false);
    }
    const participantLocale = this.props.participant.locale;
    if (participantLocale) {
      this.props.i18n.changeLanguage(participantLocale);
    }

    this.stylesLoadingInterval = setInterval(() => {
      const bodyStyles = window.getComputedStyle(document.body);
      const isInjected = bodyStyles.getPropertyValue('--loaded');
      if (isInjected) {
        this.setState({ stylesLoaded: true });
        clearInterval(this.stylesLoadingInterval);
      }
    }, 200);

    if (fetchDataPromise) {
      // Wait for participant data to load.
      await fetchDataPromise;
      if (pathContains(currentPath, ['manage'])) {
        // We want to test the path if a user tries to get to any
        // of the dashboard pages.
        this.evaluateRoute();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const prevPath = prevProps.location.pathname;
    const currentPath = this.props.location.pathname;
    const prevIsNoAuthRoute = pathContains(prevPath, noAuthRoutes);
    const isNoAuthRoute = pathContains(currentPath, noAuthRoutes);

    if (
      this.props.meta &&
      this.props.meta?.brand &&
      !this.state.stylesLoaded &&
      !this.props.meta?.brand?.custom_css
    ) {
      if (this.stylesLoadingInterval) {
        clearInterval(this.stylesLoadingInterval);
      }
      this.setState({ stylesLoaded: true });
    }

    if (prevIsNoAuthRoute && !isNoAuthRoute) {
      this.fetchData();
    }

    if (
      this.props.participant.enrollment_identifier &&
      this.differentNodes(prevProps)
    ) {
      this.evaluateRoute(this.props);
    } else if (this.props.meta.failure) {
      window.location = 'https://www.myachievement.com/';
    }

    if (
      _.has(this.props, 'meta.authentication.provider') &&
      _.includes(
        EXTERNAL_AUTH_PROVIDER_ALLOWLIST,
        this.props.meta.authentication.provider.toLowerCase(),
      ) &&
      !_.includes(filteredRoutes, this.props.location.pathname) &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.verifyCognitoParticipantData();
    }
    if (prevProps.participant.locale !== this.props.participant.locale) {
      this.props.i18n.changeLanguage(this.props.participant.locale);
    }
  }

  differentNodes = (prevProps) =>
    prevProps.participant.nodes !== this.props.participant.nodes;

  verifyCognitoParticipantData = () => {
    const { participant_auth_token, enrollment_identifier } =
      this.props.participant;

    if (
      !api.cookie.get('verify_data') &&
      participant_auth_token &&
      enrollment_identifier
    ) {
      api.participantInfo
        .updateRequired(enrollment_identifier, participant_auth_token)
        .then(({ data }) => {
          if (data.update_required) {
            this.setState({ verifyParticipantData: true });
          } else {
            this.setState({ verifyParticipantData: false });
            api.cookie.set('verify_data', 'false', { expires: 1 });
          }
        })
        .catch((error) => logError(error));
    }
  };

  onBackButtonEvent = (e) => {
    e.preventDefault();
    this.getAllData();
  };

  generateGetRequestQueryParams = (url_params) => {
    return _.map(url_params, (value, field) =>
      field && value ? `${field}=${value}&` : null,
    ).join('');
  };

  getAllData: GetAllData = async () => {
    const requests = [this.props.getLanding()];
    if (!this.props.participant.enrollment_identifier) {
      const storage = api.local.get(STORAGE_PARTICIPANT_AUTH);
      if (!storage.slug) {
        return;
      }
      if (storage) this.props.establishUser(storage);
    }

    if (this.props.participant.enrollment_identifier) {
      const { enrollment_identifier, participant_auth_token } =
        this.props.participant;

      requests.push(
        this.props.getMeta(participant_auth_token, enrollment_identifier),
      );
      const { payload: user, error } = await this.props.getUser(
        enrollment_identifier,
        participant_auth_token,
      );
      if (!error)
        storeEnrollmentLocalStorage({ ...user, slug: this.props.meta.slug });
    }
    await Promise.all(requests);

    this.setIsLoading(false);
  };

  submitUserData: SubmitUserData = async (user_data, transition = true) => {
    const { t } = this.props;
    this.props.clearErrors();
    this.setIsLoading(transition);

    const url_params = getFilteredUrlParams(window.location.search);

    const { enrollment_identifier = ``, participant_auth_token = `` } =
      this.props.participant;

    const payload = {
      ...user_data,
      ...url_params,
      client_info: userAgentInfo,
    };

    const { payload: submitPayload, error } = await this.props.updateUser(
      payload,
      enrollment_identifier,
      participant_auth_token,
    );

    this.verifyResponse({
      payload,
      submitPayload,
      error,
      callbackFn: async (otp_code) => {
        this.setState({ otpIsLoading: true, otpError: null });
        await this.props
          .updateUser(
            { ...payload, otp_code },
            enrollment_identifier,
            participant_auth_token,
          )
          .then(({ error, payload }) => {
            if (!error) {
              // storeEnrollmentLocalStorage(payload);
              // If the current pathname does not match, the match value will be null
              const match = matchPath(this.props.location?.pathname, {
                path: '/manage/settings',
                exact: true,
                strict: false,
              });
              if (match) {
                this.setState({
                  otpSuccessMessage: t(
                    'routes.enrollment.verifyPhone.otpValid',
                  ),
                });
              } else {
                this.verifyPhoneNumberHandleClose();
              }
            } else {
              this.setState({
                otpError:
                  payload?.json?.message ||
                  t('routes.enrollment.verifyPhone.otpInvalid'),
              });
            }
          })
          .finally(() => {
            this.setState({ otpIsLoading: false });
          });
      },
    });

    return { payload: submitPayload, error, errorMsg: this.props.errors };
  };

  submitSurveyData: SubmitSurveyData = async (
    payload,
    transition = true,
    node_slug,
  ) => {
    const { t } = this.props;
    this.props.clearErrors();
    this.setIsLoading(transition);

    const {
      enrollment_identifier = ``,
      participant_auth_token = ``,
      nodes,
    } = this.props.participant;

    const { payload: submitPayload, error } = await this.props.submitSurvey(
      payload,
      enrollment_identifier,
      node_slug || nodes[0].slug,
      participant_auth_token,
    );

    this.verifyResponse({
      payload,
      submitPayload,
      error,
      callbackFn: async (otp_code) => {
        this.setState({ otpIsLoading: true, otpError: null });
        await this.props
          .submitSurvey(
            [...payload, { attribute: 'otp_code', value: otp_code }],
            enrollment_identifier,
            node_slug || nodes[0].slug,
            participant_auth_token,
          )
          .then(({ error, payload }) => {
            if (!error) {
              this.verifyPhoneNumberHandleClose();
            } else {
              this.setState({
                otpError:
                  payload?.json?.message ||
                  t('routes.enrollment.verifyPhone.otpInvalid'),
              });
            }
          })
          .finally(() => {
            this.setState({ otpIsLoading: false });
          });
      },
    });

    this.setIsLoading(false);
    if (error) {
      throw new Error(submitPayload?.json?.error);
    }
    return { payload: submitPayload };
  };

  verifiedSubmit: VerifiedSubmit = async (
    payload,
    formAction,
    submitFunction,
  ) => {
    const { t } = this.props;
    if (formAction) {
      payload = { ...payload, form_action: formAction };
    }
    const recaptchaKey = window.env.RECAPTCHA_ENTERPRISE_SCORE_SITE_KEY;
    if (
      this.props.meta?.recaptcha_version === RECAPTCHA_VERSIONS.SCORE &&
      // Protect code in case reCaptcha script doesn't load
      window.grecaptcha.enterprise
    ) {
      await new Promise((resolve) => {
        window.grecaptcha.enterprise.ready(() => {
          resolve();
        });
      });
      const token = await window.grecaptcha.enterprise.execute(recaptchaKey, {
        action: formAction,
      });
      payload = {
        ...payload,
        recaptcha_token: token,
      };
    }
    try {
      await submitFunction(payload);
    } catch (err) {
      // if 499 we need to do the OTP verify flow.
      if (err?.response?.status === 499) {
        this.setState({
          otpPhoneNumber: payload.payload?.phone_number,
          otpVerify: true,
          otpCallback: async (otp_code) => {
            this.setState({ otpIsLoading: true, otpError: null });
            try {
              await submitFunction({ ...payload, otp_code });
              this.setState({ otpVerify: false, otpError: null });
            } catch (err) {
              this.setState({
                otpError: err?.response?.data?.error
                  ? getStringError(err?.response?.data?.error)
                  : t('components.error.defaultError'),
              });
              throw err;
            } finally {
              this.setState({ otpIsLoading: false });
            }
          },
        });
        return;
      }
      // Re-throw so that the submitting tile can catch the error and recover.
      throw err;
    }
  };

  findNodes(props) {
    switch (true) {
      case _.has(props, 'participant') && _.has(props.participant, 'nodes'):
        return props.participant;
      case _.has(this.props.landing, 'nodes'):
        return this.props.landing;
      default:
        return { nodes: [], layout: '' };
    }
  }

  /**
   * Returns the node that matches the provided slug, if any.
   * @description https://achievements.atlassian.net/browse/SI-7193
   */
  getCurrentNode: GetCurrentNode = (slug) => {
    return this.props.participant.nodes?.find((node) => node.slug === slug);
  };

  getParticipantLayout = (layout, participant) => {
    switch (layout) {
      case 'education':
        return '';
      case 'informed_consent':
        if (participant && participant.informed_consents.length > 0) {
          return 'dashboard';
        } else {
          return layout;
        }
      default:
        return layout;
    }
  };

  evaluateRoute: EvaluateRoute = (newProps = this.props) => {
    const data = this.findNodes(newProps);

    const { history, participant } = this.props;
    const currentPath = history.location.pathname;
    const { nodes } = data;
    const { layout } = data;
    const currentLayout = this.getParticipantLayout(layout, participant);
    this.setState({ error: '' });

    // Redirect users away from dashboard pages that require a login (i.e.: dashboard,
    // settings, ehr, etc). Each of the pages after enrollment is completed
    // contain 'manage' in the path.
    if (
      pathContains(currentPath, ['manage']) &&
      (!participant.enrollment_identifier ||
        !participant.participant_auth_token)
    ) {
      return history.replace('/log-in');
    }

    if (nodes.length <= 0) {
      if (this.state.loading) {
        return;
      }
      // if there are no nodes
      console.error('No nodes present');
      return history.replace(`/404`);
    }

    if (
      // need to stop the current layout from navigating away from settings/summary
      // || currentPath === `/` <- this allows us to have routing to education, but we dont have the data yet
      (currentLayout === `dashboard` &&
        pathContains(currentPath, [
          'faq',
          'settings',
          'summary',
          'dashboard',
          'update_email',
          'camcog_success',
        ])) ||
      // special case for Education layout
      // using layout here because we switch currentLayout to a blank string
      (layout === 'education' && pathContains(currentPath, ['log-in'])) ||
      pathContains(currentPath, [
        'terms',
        'one_click_contribution',
        'email-contribution',
      ]) ||
      currentLayout.includes(currentPath) ||
      layout === 'follow_up' ||
      pathContains(currentPath, ['error'])
    ) {
      return;
    }
    if (currentLayout === 'survey') {
      history.replace(
        `/manage/pre-dashboard/contribution/${participant.nodes[0].slug}`,
      );
      return;
    }
    history.replace(
      `/${currentLayout}${window.location.search}${window.location.hash}`,
    );
  };

  mergeProps = (props, ...etc) => {
    const { error } = this.state;

    return {
      ...props,
      ...{ ...etc },
      evaluateRoute: this.evaluateRoute,
      mergeProps: this.mergeProps,
      submitUserData: this.submitUserData,
      submitSurveyData: this.submitSurveyData,
      verifiedSubmit: this.verifiedSubmit,
      getAllData: this.getAllData,
      getCurrentNode: this.getCurrentNode,
      error,
      ...this.props,
      match: props.match,
    };
  };

  commonRender = (Module, props) => {
    const {
      t,
      participant: { status },
      history,
    } = this.props;
    const { nodes } = this.props.participant.nodes?.length
      ? this.props.participant
      : this.props.landing;

    if (!nodes || nodes.length === 0) {
      return <FourOhFour />;
    }

    const currentNode = this.getCurrentNode(props.match.params.slug);
    const currentPath = history.location.pathname;

    if (
      status === ENROLLMENT_STATUSES.suspended &&
      !pathContains(currentPath, ['settings'])
    ) {
      return (
        <FailurePage
          content={currentNode?.content}
          getAllData={this.getAllData}
          history={history}
          status={status}
        />
      );
    }
    if (
      props.match.params.slug &&
      !currentNode &&
      // The Contribution component handles its own errors.
      // TODO: Do the same thing for all other components that need this logic.
      props.match.path !== '/manage/dashboard/contribution/:slug' &&
      nodes[0].type !== 'Milestone'
    ) {
      return (
        <ErrorPage
          title={t('components.error.contributionDoesNotExist')}
          message={t('components.error.contributionDoesNotExistMessage')}
          buttonType="return-to-dashboard"
        />
      );
    } else if (
      props.match.params.slug &&
      !currentNode &&
      // The Contribution component handles its own errors.
      // TODO: Do the same thing for all other components that need this logic.
      props.match.path === '/manage/pre-dashboard/contribution/:slug' &&
      nodes[0].type === 'Milestone'
    ) {
      history.replace(`/manage/dashboard`);
    }
    return Module && nodes.length >= 1 && !props.meta ? (
      <Module {...this.mergeProps(props)} />
    ) : (
      <LoadingIndicator />
    );
  };

  verifyPhoneNumberHandleClose = () => {
    this.props.clearErrors();
    this.setState({
      otpVerify: false,
      otpCallback: null,
      otpPhoneNumber: null,
      otpError: null,
    });
  };

  verifyResponse = ({ payload, submitPayload, error, callbackFn }) => {
    this.setIsLoading(false);

    if (submitPayload?.status === 499) {
      this.setState({
        otpVerify: true,
        otpPhoneNumber: payload?.phone_number,
        otpCallback: (otp_code) => callbackFn(otp_code),
      });
    }
  };

  render() {
    const {
      meta,
      participant: { email, phone_number },
    } = this.props;
    const { loading, stylesLoaded } = this.state;
    const recaptchaKey = window.env.RECAPTCHA_ENTERPRISE_SCORE_SITE_KEY;
    const recaptchaEnabled = !!(
      recaptchaKey && meta?.recaptcha_version === RECAPTCHA_VERSIONS.SCORE
    );

    const isWebview = getIsWebview();
    const shouldUseZendesk = meta.customer_service_provider === 'Zendesk';
    const zendeskApiKey =
      meta.zendesk_api_key || '84b6e074-9852-49f5-9145-5aebe426dfe3';
    const shouldUseFreshdesk = meta.customer_service_provider === 'Freshdesk';
    const freshdeskSettings = { widget_id: 42000000540 };
    if (meta.locale) {
      freshdeskSettings.locale = meta.locale;
    }

    if (meta.is_program) {
      return (
        <ErrorBoundary>
          <Program {...this.props} />
        </ErrorBoundary>
      );
    }

    const customScriptTags = createCustomScriptTag(meta?.brand?.custom_head);
    const customMetaTags = createCustomMetaTag(meta?.brand?.custom_head);
    const customLinkTags = createCustomLinkTag(meta?.brand?.custom_head);
    const customStyleTags = createCustomStyleTag(meta?.brand?.custom_head);

    const onLoad = `
    zE('webWidget', 'prefill', {
      email: {
        value: '${email}',
        readOnly: true
      },
    });
    `;

    // This is the tricky part, we know the external styles are loaded through this styles, this does not impact custom css
    const stylesLoadedInCustomCSS = ':root {--loaded: #0077bf;}';

    return (
      <MainLayout
        loading={loading}
        stylesLoaded={stylesLoaded}
        verifyParticipantData={{
          updateData: this.state.verifyParticipantData,
          verified: () => this.setState({ verifyParticipantData: false }),
        }}
        setIsLoading={this.setIsLoading}
      >
        <Helmet>
          <title>
            {meta.brand?.branding_json?.page_title ?? 'Achievement'}
          </title>
          <meta content={meta.keywords} name="keywords" />
          <meta content="A study run by Evidation Health" name="description" />
          <meta content="Evidation Health" name="author" />
          <meta content="help@myachievement.com" name="contact" />
          {meta.brand?.custom_css && (
            <style data-name="custom_css">
              {meta.brand.custom_css + stylesLoadedInCustomCSS}
            </style>
          )}
          {customScriptTags &&
            customScriptTags.map((script, id) => (
              <script
                key={`custom_user_script_${id}`}
                {...script.attributes}
              >{`${script.children.join('\n')}`}</script>
            ))}
          {customMetaTags &&
            customMetaTags.map((tag, id) => (
              <meta key={`custom_user_meta_tag_${id}`} {...tag.attributes} />
            ))}
          {customLinkTags &&
            customLinkTags.map((tag, id) => (
              <link key={`custom_user_link_tag_${id}`} {...tag.attributes} />
            ))}
          {customStyleTags &&
            customStyleTags.map((tag, id) => (
              <style
                key={`custom_user_link_tag_${id}`}
                {...tag.attributes}
              >{`${tag.children}`}</style>
            ))}
          {!isWebview && shouldUseZendesk && (
            <script
              id="ze-snippet"
              src={`https://static.zdassets.com/ekr/snippet.js?key=${zendeskApiKey}`}
              onload={email && onLoad}
            />
          )}
          {!isWebview && shouldUseZendesk && (
            <script
              type="text/javascript"
              data-testid="Zendesk_Customer_Service"
            >
              {`
              window.zESettings = {
                webWidget: {
                  zIndex: 999,
                  position: { horizontal: 'right' },
                  offset: {
                    vertical: '110px',
                    mobile: {
                      vertical: '15px',
                      horizontal: '0px'
                    }
                  },
                  contactForm: {
                    tags: ['${meta.slug}'],
                    fields: [
                      { id: 360007180893, prefill: { '*': '${meta.slug}'} },
                      { id: 360018244493, prefill: { '*': '${phone_number}' } },
                    ]
                  },
                  ${
                    meta.brand?.branding_json
                      ? `
                        color: {
                          theme: '${meta.brand.branding_json.color_4}',
                          launcher: '${meta.brand.branding_json.color_4}',
                          launcherText: '${meta.brand.branding_json.color_6}'
                        },
                      `
                      : ''
                  }
                }
              };
          `}
            </script>
          )}
          {!isWebview && shouldUseFreshdesk && (
            <script
              type="text/javascript"
              src="https://widget.freshworks.com/widgets/42000000540.js"
              async
              defer
            ></script>
          )}
          {!isWebview && shouldUseFreshdesk && (
            <script
              type="text/javascript"
              data-testid="Freshdesk_Customer_Service"
            >
              {`
                window.fwSettings=${JSON.stringify(freshdeskSettings)};
                !function(){
                  if("function"!=typeof window.FreshworksWidget)
                  {var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}
                }()
              `}
            </script>
          )}
          {recaptchaEnabled && !isWebview && (
            <script
              src={`https://www.google.com/recaptcha/enterprise.js?render=${recaptchaKey}`}
            ></script>
          )}
        </Helmet>

        {meta.brand?.branding_json?.favicons ? (
          <Helmet>
            {meta.brand.branding_json.favicons.map((favicon) => (
              <link
                rel="shortcut icon"
                type={favicon.type}
                sizes={favicon.sizes}
                href={favicon.href}
                key={favicon.href}
              />
            ))}
          </Helmet>
        ) : (
          <Helmet>
            {/* Default to the Achievement favicons if the brand doesn't exist */}
            <link
              rel="shortcut icon"
              type="image/png"
              sizes="32x32"
              href="https://s3.amazonaws.com/assets.myachievement.com/study/favicon-32x32.png"
            />
            <link
              rel="shortcut icon"
              type="image/png"
              sizes="16x16"
              href="https://s3.amazonaws.com/assets.myachievement.com/study/favicon-16x16.png"
            />
            <link
              rel="shortcut icon"
              href="https://s3.amazonaws.com/assets.myachievement.com/study/favicon.png"
            />
          </Helmet>
        )}

        <ErrorBoundary>
          {this.state.otpVerify && (
            <VerifyPhoneModal
              phone={this.state.otpPhoneNumber}
              error={this.state.otpError}
              isLoading={this.state.otpIsLoading}
              onClose={() =>
                this.setState({
                  otpVerify: false,
                  otpCallback: null,
                  otpPhoneNumber: null,
                  otpError: null,
                })
              }
              onDone={this.state.otpCallback}
              successMessage={this.state.otpSuccessMessage}
            />
          )}

          <Route
            exact
            path="/"
            render={(props) =>
              this.state.apiError
                ? this.commonRender(ErrorPage, props)
                : this.commonRender(L.Education, props)
            }
          />
          <Route
            exact
            path="/surveys/:slug"
            render={(props) => this.commonRender(L.FollowUp, props)}
          />
          <Route
            exact
            path="/email-contribution"
            render={(props) =>
              this.commonRender(L.OneClickPage, {
                ...props,
                fetchEnrollmentData: this.fetchData,
              })
            }
          />
          <Route
            exact
            path="/log-in"
            render={(props) => this.commonRender(L.LogIn, props)}
          />
          <Route
            exact
            path="/sign-up"
            render={(props) => this.commonRender(L.SignUp, props)}
          />
          <Route
            path="/screener"
            render={(props) => this.commonRender(L.Enrollment, props)}
          />
          <Route
            path="/event_delay"
            render={(props) => this.commonRender(L.GenericNonDashboard, props)}
          />
          <Route
            path="/informed_consent"
            render={(props) =>
              this.commonRender(L.Enrollment, {
                ...props,
                getMeta: this.props.getMeta,
                fetchEnrollmentData: this.refreshEnrollment,
              })
            }
          />
          <Route
            exact
            path="/thank-you"
            render={(props) =>
              _.isEqual(meta.slug, 'covid2020') ? (
                this.commonRender(L.ThankYou, props)
              ) : (
                <FourOhFour />
              )
            }
          />
          <Route
            path="/file_upload"
            render={(props) => this.commonRender(L.Enrollment, props)}
          />
          <Route
            exact
            path="/manage/dashboard"
            render={(props) => this.commonRender(L.Dashboard, props)}
          />
          <Route
            exact
            path="/manage/pre-dashboard/contribution/:slug"
            render={(props) =>
              this.commonRender(L.Contribution, {
                ...props,
                getAllData: this.getAllData,
              })
            }
          />
          <Route
            exact
            path="/manage/dashboard/contribution/:slug"
            render={(props) =>
              this.commonRender(L.Contribution, {
                ...props,
                getAllData: this.getAllData,
              })
            }
          />
          <Route
            exact
            path="/manage/dashboard/ehr/:slug"
            render={(props) =>
              this.commonRender(L.EHR, {
                ...props,
                getAllData: this.getAllData,
              })
            }
          />
          <Route
            exact
            path="/manage/dashboard/verify_identity/:slug"
            render={(props) => this.commonRender(L.VerifyIdentity, props)}
          />
          <Route
            path="/manage/settings"
            render={(props) => this.commonRender(L.Settings, props)}
          />
          <Route
            path="/manage/summary"
            render={(props) => this.commonRender(L.Summary, props)}
          />
          <Route
            path="/terminal_state"
            render={(props) => this.commonRender(L.TerminalState, props)}
          />
          <Route
            path="/withdrawn"
            render={(props) => this.commonRender(L.TerminalState, props)}
          />

          <Switch>
            <Route
              path="/one_click_contribution/error"
              render={(props) => this.commonRender(L.OneClickError, props)}
            />

            <Route
              path="/one_click_contribution"
              render={(props) => this.commonRender(L.OneClick, props)}
            />
          </Switch>
          <Route
            path="/login"
            exact
            render={() => <Redirect to={`/log-in`} />}
          />
          <Route
            path="/forgot-password"
            exact
            render={(props) => this.commonRender(L.ForgotPassword, props)}
          />
          <Route
            path="/dashboard"
            exact
            render={() => <Redirect to={`/manage/dashboard`} />}
          />
          <Route
            path="/dashboard/contribution/:slug"
            exact
            render={({ match }) => (
              <Redirect
                to={`/manage/dashboard/contribution/${match.params.slug}`}
              />
            )}
          />
          <Route
            path="/settings"
            exact
            render={() => <Redirect to={`/manage/settings`} />}
          />
          <Route
            path="/summary"
            exact
            render={() => <Redirect to={`/manage/summary`} />}
          />
          <Route
            path="/unauthorized"
            render={(props) => this.commonRender(L.Unauthorized, props)}
          />
          <Route
            path="/update_email"
            exact
            render={(props) => this.commonRender(L.EmailVerification, props)}
          />
          <Route
            path="/redirect_user"
            exact
            render={(props) => this.commonRender(L.RedirectUser, props)}
          />
          <Route
            path="/error"
            exact
            render={(props) => this.commonRender(L.DisplayUserError, props)}
          />
          <Route path="/404" component={FourOhFour} />
          <Route
            path="/camcog_success"
            render={(props) =>
              this.commonRender(L.CamCogSuccess, {
                ...props,
                getUser: this.props.getUser,
              })
            }
          />
          <Toastr position="top-center" />
          <ParticipantAuthTokenExpirationHandler />
        </ErrorBoundary>
        {recaptchaEnabled && (
          <div
            className="g-recaptcha"
            data-sitekey={recaptchaKey}
            data-size="invisible"
          ></div>
        )}

        {/*
         * These are used by automation engineers via Axiom.
         * See SPP-2101.
         */}
        <div style={{ display: 'none' }}>
          <div data-version-property="branch-name">
            {process.env.REACT_APP_BILLBOARD_BRANCH || ''}
          </div>
          <div data-version-property="branch-tag">
            {process.env.REACT_APP_BILLBOARD_TAG || ''}
          </div>
          <div data-version-property="release-date">
            {process.env.REACT_APP_BILLBOARD_RELEASE_DATE || ''}
          </div>
          <div data-version-property="commit-sha1">
            {process.env.REACT_APP_BILLBOARD_REVISION || ''}
          </div>
          <div data-version-property="circleci-job-url">
            {process.env.REACT_APP_CIRCLE_BUILD_URL || ''}
          </div>
        </div>
      </MainLayout>
    );
  }
}

App.propTypes = {
  participant: PropTypes.object,
  history: PropTypes.object.isRequired,
  prefetch: PropTypes.bool,
};

App.defaultProps = {
  prefetch: true,
  errors: [],
};

export default withRouter(
  connect(
    ({ participant, meta, landing, errors }) => ({
      participant,
      meta,
      landing,
      errors,
    }),
    (dispatch, ownProps) => ({
      logoutUser: (user) => dispatch(actions.logoutUser(user)),
      establishUser: (user) => dispatch(actions.establishUser(user)),
      updateUser: (user_data, enrollment_id, auth_token) =>
        dispatch(actions.updateUser(user_data, enrollment_id, auth_token)),
      loginUser: (user_data, enrollment_id, auth_token) =>
        dispatch(actions.loginUser(user_data, enrollment_id, auth_token)),
      getUser: (enrollment_id, auth_token) =>
        dispatch(actions.getUser(enrollment_id, auth_token)),
      setUser: (enrollment_id, auth_token) =>
        dispatch(actions.setUser(enrollment_id, auth_token)),
      createUser: (user) => dispatch(actions.createUser(user)),
      getMeta: (auth_token, enrollment_identifier) =>
        dispatch(actions.getMeta(auth_token, enrollment_identifier)),
      getSurvey: (slug, urlParams) =>
        dispatch(actions.getSurvey(slug, urlParams)),
      submitSurvey: (
        contribution,
        enrollment_identifier,
        node_id,
        auth_token,
        extras = {},
      ) =>
        dispatch(
          actions.submitSurveyData(
            contribution,
            enrollment_identifier,
            node_id,
            auth_token,
            extras,
          ),
        ),
      goBackSurvey: (
        action_type = 'invalidate_previous_page',
        idempotency_token,
        enrollment_identifier,
        surveyIdentifier,
        auth_token,
        occurrence = undefined,
      ) =>
        dispatch(
          actions.goBackSurveyPage(
            action_type,
            idempotency_token,
            enrollment_identifier,
            surveyIdentifier,
            auth_token,
            occurrence,
          ),
        ),
      // The `ownProps` bit here is for easy mocking.
      getLanding: ownProps.getLanding || (() => dispatch(actions.getLanding())),
      clearErrors: () => dispatch(actions.clearErrors()),
    }),
  )(withTranslation()(App)),
);
